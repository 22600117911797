<template>
    <section class="listSection listing">
        <div class="container">
            <div id="pages">
                <div class="headline">
                    <h1 v-if="model && model.data">
                        Találatok - {{ model.count_product }}db
                    </h1>
                    <hr />
                    <ul
                        class="pagination justify-content-center my-4"
                        v-if="model && model.meta"
                    >
                        <template v-for="(item, index) in model.meta.links">
                            <li
                                class="page-item"
                                v-if="item.label === '&laquo; Previous'"
                                v-bind:class="{ disabled: item.url === null }"
                                v-bind:key="'pagination-prev-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="minusPage"
                                    aria-label="Previous"
                                >
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li
                                class="page-item"
                                v-bind:class="{ disabled: item.url === null }"
                                v-else-if="item.label === 'Next &raquo;'"
                                v-bind:key="'pagination-next-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="plusPage"
                                    aria-label="Next"
                                >
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                            <li
                                class="page-item"
                                v-bind:class="{ active: item.active }"
                                v-else
                                v-bind:key="'pagination-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="setPage(item.label)"
                                    >{{ item.label }}</a
                                >
                            </li>
                        </template>
                    </ul>
                    <div class="filterIcons">
                        <i class="icon-menu" v-on:click="setSingleColumn"></i>
                        <i class="icon-nezet_2" v-on:click="setTwoColumn"></i>
                    </div>
                </div>
                <template v-for="(item, index) in model.data">
                    <ProductRow
                        :twocolumn="columnType"
                        v-on:setCompareId="setCompareId"
                        v-bind:key="'product-' + index"
                        :product-data="item"
                    ></ProductRow>
                </template>
                <div class="headline">
                    <h1 v-if="model && model.data">
                        Találatok - {{ model.count_product }}db
                    </h1>
                    <hr />
                    <ul
                        class="pagination justify-content-center my-4"
                        v-if="model && model.meta"
                    >
                        <template v-for="(item, index) in model.meta.links">
                            <li
                                class="page-item"
                                v-if="item.label === '&laquo; Previous'"
                                v-bind:class="{ disabled: item.url === null }"
                                v-bind:key="'pagination-prev-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="minusPage"
                                    aria-label="Previous"
                                >
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li
                                class="page-item"
                                v-bind:class="{ disabled: item.url === null }"
                                v-else-if="item.label === 'Next &raquo;'"
                                v-bind:key="'pagination-next-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="plusPage"
                                    aria-label="Next"
                                >
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                            <li
                                class="page-item"
                                v-bind:class="{ active: item.active }"
                                v-else
                                v-bind:key="'pagination-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="setPage(item.label)"
                                    >{{ item.label }}</a
                                >
                            </li>
                        </template>
                    </ul>
                    <div class="filterIcons">
                        <i class="icon-menu" v-on:click="setSingleColumn"></i>
                        <i class="icon-nezet_2" v-on:click="setTwoColumn"></i>
                    </div>
                </div>
            </div>
            <!-- pagination end -->
        </div>
    </section>
</template>

<script>
import ProductRow from "@/components/ProductRow";
// import ApiService from "@/core/services/api.service";
export default {
    name: "ProductList",
    components: { ProductRow },
    data() {
        return {
            model: {},
            page: 1,
            sortBy: "price_asc",
            comparList: [],
            columnType: null
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        params: function() {
            return this.$route.params.slug.split("-");
        }
    },
    beforeMount() {
        if (this.$route.query.page) {
            this.page = this.$route.query.page;
        }
        if (this.$route.query.sortBy) {
            this.sortBy = this.$route.query.sortBy;
        }
        this.getSearchData();
        if (localStorage.getItem("columnType")) {
            this.columnType = localStorage.getItem("columnType");
        }
    },
    watch: {
        $route: {
            handler: function() {
                if (this.$route.query.page) {
                    this.page = this.$route.query.page;
                }
                if (this.$route.query.sortBy) {
                    this.sortBy = this.$route.query.sortBy;
                }
                this.getSearchData();
            },
            deep: true
        }
    },
    methods: {
        setSingleColumn() {
            this.columnType = null;
            localStorage.setItem("columnType", null);
        },
        setTwoColumn() {
            this.columnType = "twocolumn";
            localStorage.setItem("columnType", "twocolumn");
        },
        setCompareId(data) {
            if (data.checked) {
                this.comparList.push(data.id);
            } else {
                const index = this.comparList.indexOf(data.id);
                if (index > -1) {
                    this.comparList.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        },
        setSortBy() {
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            arr["sortBy"] = this.sortBy;
            this.$router
                .push({
                    name: "ProductList",
                    params: this.$route.params,
                    query: arr
                })
                .catch(() => {});
        },
        minusPage() {
            this.page = parseInt(this.page) - 1;
            this.setPage(this.page);
        },
        plusPage() {
            this.page = parseInt(this.page) + 1;
            this.setPage(this.page);
        },
        setPage(pg) {
            this.page = pg;
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            arr["page"] = this.page;
            this.$router
                .push({
                    name: "ProductList",
                    params: this.$route.params,
                    query: arr
                })
                .catch(() => {});
        },
        getSearchData() {
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            if (
                this.params[0] === "nyárigumi" ||
                this.params[0] === "téligumi"
            ) {
                if (this.params[0] === "nyárigumi") {
                    arr["m1nev"] = "nyárigumi";
                } else {
                    arr["m1nev"] = "téligumi";
                }

                if (this.params.length > 2) {
                    arr["width"] = this.params[2];
                    arr["profile"] = this.params[3];
                    arr["coll"] = this.params[4];
                }
            } else if (
                isNaN(parseInt(this.params[0])) &&
                (this.params[1] === "nyárigumi" ||
                    this.params[1] === "téligumi")
            ) {
                arr["emarka"] = this.params[0];
                if (this.params[1] === "nyárigumi") {
                    arr["m1nev"] = "nyárigumi";
                } else {
                    arr["m1nev"] = "téligumi";
                }

                if (this.params.length > 3) {
                    arr["width"] = this.params[3];
                    arr["profile"] = this.params[4];
                    arr["coll"] = this.params[5];
                }
            } else {
                arr["width"] = this.params[0];
                arr["profile"] = this.params[1];
                arr["coll"] = this.params[2];
            }
            arr["page"] = this.page;
            arr["sortBy"] = this.sortBy;

            this.$http
                .get(`${this.url}/products/list?${this.buildQueryString(arr)}`)
                .then(response => {
                    this.model = response.data;
                    if (document.getElementById("pages") !== null) {
                        $("html, body").animate(
                            {
                                scrollTop: $("#pages").offset().top - 150
                            },
                            1000
                        );
                    }
                });
        }
    }
};
</script>

<style scoped></style>
